import { mockProduct } from "./product";

const mockOrderList = [
  {
    supp_order_no: 1,
    supp_org_no: "ee2e4677-3731-45eb-a23d-8a7197b1d1b7",
    contr_no: {
      contr_no: 1,
      sort: null,
      contr_date: "2023-04-04T12:00:00",
      item_price_sum: 560000000,
      dpst_amt: 50500000,
      final_price_tot: 505000000,
      blnc_amt: 454500000,
      order_id: "VH23D04-010",
      order_status: "Preparing Delivery",
      recip_phone_num: "022-456-7890",
      recip_nm: "Phạm Bian",
      recip_email: "pham@customer.com",
      deliv_due_date: "2023-12-14",
      cust_no: {
        id: "b6bbb354-1b97-4b8c-99d6-1db435f12d15",
        first_name: "Phạm Bian",
        last_name: null,
        email: "pham@customer.com",
        mob_phone: "022-456-7890",
      },
      plan_no: {
        plan_no: 3,
        sort: null,
        status: "published",
        plan_nm: "W2-OT5 / Floor 2 / CAN # 208",
        plan_type: "plan",
        plan_status: "completed",
        plan_data_url: null,
        plan_data_json: null,
        tag: ["Cosy"],
        orig_plan_no: {
          plan_no: 1,
          sort: null,
          status: "published",
          plan_nm: "W2-OT5 Template #2",
          plan_type: "template",
          orig_plan_no: null,
          apt_type_no: 2,
          agent_no: "8f50f65f-9fd4-4017-b6e8-bb780a5480a3",
          plan_status: "completed",
          plan_data_url: null,
          plan_data_json: null,
          plan_rep_img: "de3ad448-279c-4553-950e-a4ca9ed83600",
          plan_2d_img: "980adeec-e008-400a-a70e-12d037fd6cca",
          plan_thumb_img: "8e19728b-b6ed-4c13-a0eb-a36f06dd5813",
          tag: null,
          plan_rend_img: [],
        },
        apt_type_no: {
          apt_type_no: 2,
          status: "published",
          sort: null,
          apt_type_nm:
            "[ Vinhomes West Point ] WEST2 / Tầng 2-5A / W2-OT5 ( 2PN, 71.8 ㎡ )",
          cmplx_no: 1,
          bldg_nm: "WEST2",
          bldg_type: "apartment",
          can_type_cd: "W2-OT5",
          can_type_nm: null,
          can_info_floor: "2-5A",
          can_info_room: "2PN",
          can_gross_area: "71.8",
          can_net_area: "64.5",
          apt_2d_img: "b9900e01-289a-42cf-af0d-976ba0b02884",
          apt_3d_model: null,
          apt_3d_url: null,
        },
        agent_no: "8f50f65f-9fd4-4017-b6e8-bb780a5480a3",
        plan_rep_img: "fe3e3e5d-e968-42db-b7f2-8398ac048542",
        plan_2d_img: "980adeec-e008-400a-a70e-12d037fd6cca",
        plan_thumb_img: "8e19728b-b6ed-4c13-a0eb-a36f06dd5813",
      },
      plcy_no: 2,
      deliv_addr_no: {
        addr_no: "22a74c1c-9624-4388-ad5d-db41a8f7e97b",
        status: "published",
        sort: null,
        date_created: "2023-04-04T09:43:34.290Z",
        date_updated: null,
        addr_lv1: "#208, WEST2, Vinhoms West Point, Phạm Hùng",
        addr_lv2: "Mễ Trì,Nam Từ Liêm",
        addr_nm: "Phạm Bian Delivery",
        addr_type: "contract",
        addr_city_prov: "Hà Nội.",
        addr_post_code: "12012",
        gps_lat_deg: null,
        gps_long_deg: null,
      },
    },
  },
  {
    supp_order_no: 2,
    supp_org_no: "ee2e4677-3731-45eb-a23d-8a7197b1d1b7",
    contr_no: {
      contr_no: 2,
      sort: null,
      contr_date: "2023-04-04T12:00:00",
      item_price_sum: 560000000,
      dpst_amt: 50500000,
      final_price_tot: 505000000,
      blnc_amt: 454500000,
      order_id: "VH23D04-011",
      order_status: "Delivered",
      recip_phone_num: "022-456-7890",
      recip_nm: "Phạm Bian",
      recip_email: "pham@customer.com",
      deliv_due_date: "2023-12-15",
      cust_no: {
        id: "b6bbb354-1b97-4b8c-99d6-1db435f12d15",
        first_name: "Phạm Bian",
        last_name: null,
        email: "pham@customer.com",
        mob_phone: "022-456-7890",
      },
      plan_no: {
        plan_no: 4,
        sort: null,
        status: "published",
        plan_nm: "W2-OT5 / Floor 2 / CAN # 209",
        plan_type: "plan",
        plan_status: "completed",
        plan_data_url: null,
        plan_data_json: null,
        tag: null,
        orig_plan_no: {
          plan_no: 1,
          sort: null,
          status: "published",
          plan_nm: "W2-OT5 Template #2",
          plan_type: "template",
          orig_plan_no: null,
          apt_type_no: 2,
          agent_no: "8f50f65f-9fd4-4017-b6e8-bb780a5480a3",
          plan_status: "completed",
          plan_data_url: null,
          plan_data_json: null,
          plan_rep_img: "de3ad448-279c-4553-950e-a4ca9ed83600",
          plan_2d_img: "980adeec-e008-400a-a70e-12d037fd6cca",
          plan_thumb_img: "8e19728b-b6ed-4c13-a0eb-a36f06dd5813",
          tag: null,
          plan_rend_img: [],
        },
        apt_type_no: {
          apt_type_no: 2,
          status: "published",
          sort: null,
          apt_type_nm:
            "[ Vinhomes West Point ] WEST2 / Tầng 2-5A / W2-OT5 ( 2PN, 71.8 ㎡ )",
          cmplx_no: 1,
          bldg_nm: "WEST2",
          bldg_type: "apartment",
          can_type_cd: "W2-OT5",
          can_type_nm: null,
          can_info_floor: "2-5A",
          can_info_room: "2PN",
          can_gross_area: "71.8",
          can_net_area: "64.5",
          apt_2d_img: "b9900e01-289a-42cf-af0d-976ba0b02884",
          apt_3d_model: null,
          apt_3d_url: null,
        },
        agent_no: "8f50f65f-9fd4-4017-b6e8-bb780a5480a3",
        plan_rep_img: "fe3e3e5d-e968-42db-b7f2-8398ac048542",
        plan_2d_img: "980adeec-e008-400a-a70e-12d037fd6cca",
        plan_thumb_img: "8e19728b-b6ed-4c13-a0eb-a36f06dd5813",
      },
      plcy_no: 2,
      deliv_addr_no: {
        addr_no: "22a74c1c-9624-4388-ad5d-db41a8f7e97b",
        status: "published",
        sort: null,
        date_created: "2023-04-04T09:43:34.290Z",
        date_updated: null,
        addr_lv1: "#208, WEST2, Vinhoms West Point, Phạm Hùng",
        addr_lv2: "Mễ Trì,Nam Từ Liêm",
        addr_nm: "Phạm Bian Delivery",
        addr_type: "contract",
        addr_city_prov: "Hà Nội.",
        addr_post_code: "12012",
        gps_lat_deg: null,
        gps_long_deg: null,
      },
    },
  },
  {
    supp_order_no: 3,
    supp_org_no: "ee2e4677-3731-45eb-a23d-8a7197b1d1b7",
    contr_no: {
      contr_no: 3,
      sort: null,
      contr_date: "2023-04-04T12:00:00",
      item_price_sum: 560000000,
      dpst_amt: 50500000,
      final_price_tot: 505000000,
      blnc_amt: 454500000,
      order_id: "VH23D04-012",
      order_status: "Canceled",
      recip_phone_num: "022-456-7890",
      recip_nm: "Phạm Bian",
      recip_email: "pham@customer.com",
      deliv_due_date: "2023-12-15",
      cust_no: {
        id: "b6bbb354-1b97-4b8c-99d6-1db435f12d15",
        first_name: "Phạm Bian",
        last_name: null,
        email: "pham@customer.com",
        mob_phone: "022-456-7890",
      },
      plan_no: {
        plan_no: 5,
        sort: null,
        status: "published",
        plan_nm: "W2-OT5 / Floor 2 / CAN # 210",
        plan_type: "plan",
        plan_status: "completed",
        plan_data_url: null,
        plan_data_json: null,
        tag: null,
        orig_plan_no: {
          plan_no: 1,
          sort: null,
          status: "published",
          plan_nm: "W2-OT5 Template #2",
          plan_type: "template",
          orig_plan_no: null,
          apt_type_no: 2,
          agent_no: "8f50f65f-9fd4-4017-b6e8-bb780a5480a3",
          plan_status: "completed",
          plan_data_url: null,
          plan_data_json: null,
          plan_rep_img: "de3ad448-279c-4553-950e-a4ca9ed83600",
          plan_2d_img: "980adeec-e008-400a-a70e-12d037fd6cca",
          plan_thumb_img: "8e19728b-b6ed-4c13-a0eb-a36f06dd5813",
          tag: null,
          plan_rend_img: [],
        },
        apt_type_no: {
          apt_type_no: 2,
          status: "published",
          sort: null,
          apt_type_nm:
            "[ Vinhomes West Point ] WEST2 / Tầng 2-5A / W2-OT5 ( 2PN, 71.8 ㎡ )",
          cmplx_no: 1,
          bldg_nm: "WEST2",
          bldg_type: "apartment",
          can_type_cd: "W2-OT5",
          can_type_nm: null,
          can_info_floor: "2-5A",
          can_info_room: "2PN",
          can_gross_area: "71.8",
          can_net_area: "64.5",
          apt_2d_img: "b9900e01-289a-42cf-af0d-976ba0b02884",
          apt_3d_model: null,
          apt_3d_url: null,
        },
        agent_no: "8f50f65f-9fd4-4017-b6e8-bb780a5480a3",
        plan_rep_img: "fe3e3e5d-e968-42db-b7f2-8398ac048542",
        plan_2d_img: "980adeec-e008-400a-a70e-12d037fd6cca",
        plan_thumb_img: "8e19728b-b6ed-4c13-a0eb-a36f06dd5813",
      },
      plcy_no: 2,
      deliv_addr_no: {
        addr_no: "22a74c1c-9624-4388-ad5d-db41a8f7e97b",
        status: "published",
        sort: null,
        date_created: "2023-04-04T09:43:34.290Z",
        date_updated: null,
        addr_lv1: "#208, WEST2, Vinhoms West Point, Phạm Hùng",
        addr_lv2: "Mễ Trì,Nam Từ Liêm",
        addr_nm: "Phạm Bian Delivery",
        addr_type: "contract",
        addr_city_prov: "Hà Nội.",
        addr_post_code: "12012",
        gps_lat_deg: null,
        gps_long_deg: null,
      },
    },
  },
];

export const mockLastAccessDate = "2023-02-23T16:00:00.000Z";

export const getLatestAccessDate = async () => {
  console.log("getLatestAccessDate");
  // TODO: 최근 접속 시간 api 억세스
  return Promise.resolve(mockLastAccessDate);
};

export const getOrderStatus = async (org_no) => {
  let status = {
    orders: {
      counts: fGetOrderCount({
        supp_org_no: org_no,
        order_status: "Preparing Delivery",
      }),
      newItem: false,
    },
    delivered: {
      counts: fGetOrderCount({
        supp_org_no: org_no,
        order_status: "Delivered",
      }),
      newItem: false,
    },
    canceled: {
      counts: fGetOrderCount({
        supp_org_no: org_no,
        order_status: "Canceled",
      }),
      newItem: false,
    },
    products: {
      counts:
        mockProduct.filter((item) => item.suppl_no === org_no).length || 0,
      newItem: false,
    },
  };

  return Promise.resolve(status);
};

export const getOrder = async ({ contr_no }) => {
  console.log(`getOrder contr_no = ` + contr_no);
  let order = mockOrderList.find(
    (item) => item.contr_no.contr_no.toString() === contr_no
  );
  console.log(`getOrder detail = ` + order);
  return Promise.resolve(order);
};

const fGetOrderCount = (
  supp_org_no,
  order_status,
  keyword,
  searchDateStart,
  searchDateEnd
) => {
  return fGetOrderList(
    supp_org_no,
    order_status,
    keyword,
    searchDateStart,
    searchDateEnd
  ).length;
};

export const getOrderCount = async ({
  supp_org_no,
  order_status,
  keyword,
  searchDateStart,
  searchDateEnd,
}) => {
  return Promise.resolve(
    fGetOrderCount(
      supp_org_no,
      order_status,
      keyword,
      searchDateStart,
      searchDateEnd
    )
  );
};

const fGetOrderList = ({
  supp_org_no,
  order_status,
  keyword,
  searchDateStart,
  searchDateEnd,
}) => {
  // TODO: 공급자 주문 묵록 api 억세스
  console.log(`getOrderList supp_org_no : ${supp_org_no}`);
  console.log(`getOrderList order_status : ${order_status}`);
  console.log(`getOrderList keyword : ${keyword}`);
  console.log(`getOrderList searchDateStart : ${searchDateStart}`);
  console.log(`getOrderList searchDateEnd : ${searchDateEnd}`);
  let orderList = mockOrderList
    .filter((item) => item.supp_org_no === supp_org_no)
    .filter((item) => {
      console.log(`item.contr_no.order_status : ${item.contr_no.order_status}`);
      console.log(
        `item.contr_no.order_status === order_status ? ${
          item.contr_no.order_status === order_status
        }`
      );
      return item.contr_no.order_status === order_status;
    })
    .filter((item) => {
      console.log(
        `new Date(searchDateStart) <= new Date(item.contr_no.contr_date) <= new Date(searchDateEnd) : ${
          new Date(searchDateStart) <=
          new Date(item.contr_no.contr_date) <=
          new Date(searchDateEnd)
        } date:${item.contr_no.contr_date}`
      );
      return (
        (item.contr_no.plan_no.plan_nm || "") +
        "|" +
        (item.contr_no.cust_no.first_name || "") +
        "|" +
        (item.contr_no.cust_no.last_name || "")
      )
        .toLowerCase()
        .includes(keyword?.toLowerCase() || "");
    })
    .filter((item) => {
      return (
        (!searchDateStart ||
          (searchDateStart &&
            new Date(searchDateStart) <= new Date(item.contr_no.contr_date))) &&
        (!searchDateEnd ||
          (searchDateEnd &&
            new Date(item.contr_no.contr_date) <= new Date(searchDateEnd)))
      );
    });

  return orderList;
};

export const getOrderList = async ({
  supp_org_no,
  order_status,
  keyword,
  searchDateStart,
  searchDateEnd,
}) => {
  return Promise.resolve(
    fGetOrderList({
      supp_org_no,
      order_status,
      keyword,
      searchDateStart,
      searchDateEnd,
    })
  );
};
