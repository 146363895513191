export const mockProduct = [
  {
    date_created: "2023-04-02T13:11:00.383Z",
    date_updated: "2023-04-02T13:23:45.326Z",
    prod_no: 1,
    sort: null,
    status: "published",
    user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
    user_updated: "e28c6320-05b9-4613-a833-f14faa281efc",
    prod_nm:
      "14 Inch Metal Platform Beds w/ Heavy Duty Steel Slat Mattress Foundation (No Box Spring Needed), Black",
    suppl_no: "b67aafaf-faa6-4e7c-ad22-35c7357eb224",
    thumb_img: null,
    prod_img: "65321e80-9197-43a7-863a-dce5c659a641",
    prod_img_files: ["65321e80-9197-43a7-863a-dce5c659a641"],
    prod_3d_model: null,
    prod_3d_url: null,
    prod_desc:
      'Steel GOOD LOOKS, CONFIDENT STYLE - Thousands of positive reviews don’t lie - the Shalini, with its diamond pattern stitching that brightens up any room and easy-as-pie assembly, is highly rated for a reason.Item Dimensions : 79.5"L x 59.5"W x 14"H.Maximum weight recommendation:330 pounds Durable and noise free construction with heavy duty steel 9 leg and center bar support 12 inches vertical clearance for convenient under the bed storage space; Super sturdy and durable steel slats 100 percent steel construction Easy to assemble; No tools required and assembled in minutes Product Dimension: (L) 60 inches x (W) 80 inches x (H) 14 inches',
    prod_type: "furniture",
    brand_nm: "Best Price Mattress",
    model_num: null,
    prod_unit_price: 2685465,
    prod_size: "Queen : 2019 cm(L) x 151.1 cm(W) x 35.6 cm(H)",
    prod_height: "151.1",
    prod_length: "201.9",
    prod_depth_height: "35.6",
    prod_weight: "15.84 kg",
    prod_type_lv1: {
      prod_type_no: 1,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Furniture",
      prod_type_lvl: 1,
      parnt_type_no: null,
    },
    prod_type_lv2: {
      prod_type_no: 2,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Mattress",
      prod_type_lvl: 2,
      parnt_type_no: 1,
    },
    made_by: "Best Price Mattress",
    made_in: "China",
    cat_by_mat: ["steel"],
    cat_by_room: ["bedroom"],
    cat_by_color: ["black"],
    cat_by_style: ["modern/contemporary"],
    cat_by_type: ["Mattress"],
    tag: ["Mattress", "White"],
    prod_info_url:
      "https://www.ubuy.vn/en/product/3EQ494-best-price-mattress-14-inch-metal-platform-beds-w-heavy-duty-steel-slat-mattress-foundation-no-box-spring-needed-black",
  },
  {
    date_created: "2023-04-02T13:37:34.550Z",
    date_updated: null,
    prod_no: 2,
    sort: null,
    status: "published",
    user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
    user_updated: null,
    prod_nm: "Giường gỗ óc chó Xuất Khẩu Nhật Bản GI-OC01",
    suppl_no: "ee2e4677-3731-45eb-a23d-8a7197b1d1b7",
    thumb_img: null,
    prod_img: "137b9f5c-1ae0-4612-a543-5ba9f617caf7",
    prod_img_files: [
      "137b9f5c-1ae0-4612-a543-5ba9f617caf7",
      "51379460-0d4c-4042-8640-ed9e80f9df4e",
      "d41335fe-38c2-41f6-a776-ca314ce76af9",
      "e0a2f973-76f5-4401-9471-7d77b6fb28e6",
    ],
    prod_3d_model: null,
    prod_3d_url: null,
    prod_desc:
      "Giường ngủ không những là nơi để ngủ mà còn là nơi nghỉ ngơi, điểm dừng chân của sự mệt mỏi và ngọt ngào. Giường ngủ là giúp người nằm cảm thấy thoải mái, dễ chịu và tràn đầy sức sống sau một giấc ngủ dài. Một đồ vật hữu ích và cần thiết cho mọi gia đình, một đồ vật có thể xoá tan mệt mỏi.",
    prod_type: "furniture",
    brand_nm: "BIZNOITHAT",
    model_num: "Gi-OC01",
    prod_unit_price: 22000000,
    prod_size: "1.6 m(W) x 2.0 m(H)",
    prod_height: "1600",
    prod_length: "2000",
    prod_depth_height: "",
    prod_weight: null,
    prod_type_lv1: {
      prod_type_no: 1,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Furniture",
      prod_type_lvl: 1,
      parnt_type_no: null,
    },
    prod_type_lv2: {
      prod_type_no: 3,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Bed",
      prod_type_lvl: 2,
      parnt_type_no: 1,
    },
    made_by: null,
    made_in: "Vietnam",
    cat_by_mat: ["wood"],
    cat_by_room: ["bedroom"],
    cat_by_color: ["dark", "wood/brown"],
    cat_by_style: ["modern/contemporary"],
    cat_by_type: ["Bed"],
    tag: ["Bed", "Brown"],
    prod_info_url: "https://biznoithat.com/giuong-go-oc-cho-biznoithat-gi-oc01",
  },
  {
    date_created: "2023-04-02T13:43:26.487Z",
    date_updated: "2023-04-02T14:02:19.415Z",
    prod_no: 3,
    sort: null,
    status: "published",
    user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
    user_updated: "e28c6320-05b9-4613-a833-f14faa281efc",
    prod_nm: "Tủ quần áo gỗ óc chó ",
    suppl_no: "ee2e4677-3731-45eb-a23d-8a7197b1d1b7",
    thumb_img: null,
    prod_img: "fdce2e23-4385-408a-968f-c74bb3c9a724",
    prod_img_files: ["fdce2e23-4385-408a-968f-c74bb3c9a724"],
    prod_3d_model: null,
    prod_3d_url: null,
    prod_desc:
      "Thiết kế đơn giản, tinh tế và sang trọng Tủ quần áo gỗ óc chó TA001-2020W  được thiết kế đầy phong cách mang tính thẩm mỹ cao, màu sắc trang nhã mang đặc trưng của gỗ óc chó, kiểu dáng tinh tế tạo nên cảm giác thoải mái và dễ chịu cho các thành viên trong gia đình, mỗi khi sử dụng.  Vật Liệu: Gỗ Óc Chó Bắc Mỹ Màu Sắc: Walnut tự nhiên Xuất Xứ: SX tại Việt Nam, Xuất khẩu đi Mỹ Bảo hành: 12 tháng",
    prod_type: "furniture",
    brand_nm: "BIZNOITHAT",
    model_num: "TA001-2020W",
    prod_unit_price: 42000000,
    prod_size: "3.5 m(W) x 2.0 m(H)",
    prod_height: "3500",
    prod_length: "2000",
    prod_depth_height: "",
    prod_weight: null,
    prod_type_lv1: {
      prod_type_no: 1,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Furniture",
      prod_type_lvl: 1,
      parnt_type_no: null,
    },
    prod_type_lv2: {
      prod_type_no: 4,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Wardrobe",
      prod_type_lvl: 2,
      parnt_type_no: 1,
    },
    made_by: null,
    made_in: "Vietnam",
    cat_by_mat: ["wood"],
    cat_by_room: ["bedroom"],
    cat_by_color: ["wood/brown"],
    cat_by_style: ["retro"],
    cat_by_type: ["Wardrobe"],
    tag: ["Wardrobe", "Brown"],
    prod_info_url: "https://biznoithat.com/tu-quan-ao-go-oc-cho-ta001-2020w-1",
  },
  {
    date_created: "2023-04-02T13:46:53.490Z",
    date_updated: null,
    prod_no: 4,
    sort: null,
    status: "published",
    user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
    user_updated: null,
    prod_nm: "Tủ đầu giường gỗ TĐG-OC02",
    suppl_no: "ee2e4677-3731-45eb-a23d-8a7197b1d1b7",
    thumb_img: null,
    prod_img: "275afb5e-dfb3-46b3-8219-35d1f78112eb",
    prod_img_files: ["275afb5e-dfb3-46b3-8219-35d1f78112eb"],
    prod_3d_model: null,
    prod_3d_url: null,
    prod_desc: null,
    prod_type: "furniture",
    brand_nm: "BIZNOITHAT",
    model_num: "TĐG-OC02",
    prod_unit_price: 4900000,
    prod_size: null,
    prod_height: "400",
    prod_length: "600",
    prod_depth_height: "500",
    prod_weight: null,
    prod_type_lv1: {
      prod_type_no: 1,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Furniture",
      prod_type_lvl: 1,
      parnt_type_no: null,
    },
    prod_type_lv2: {
      prod_type_no: 5,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Chiffonier",
      prod_type_lvl: 2,
      parnt_type_no: 1,
    },
    made_by: null,
    made_in: null,
    cat_by_mat: null,
    cat_by_room: null,
    cat_by_color: ["wood/brown"],
    cat_by_style: null,
    cat_by_type: ["Chiffonier"],
    tag: ["Chiffonier"],
    prod_info_url: "https://biznoithat.com/tu-dau-giuong-go-oc-cho-tdg-oc02",
  },
  {
    date_created: "2023-04-02T13:55:05.789Z",
    date_updated: "2023-04-02T15:47:32.352Z",
    prod_no: 5,
    sort: null,
    status: "published",
    user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
    user_updated: "e28c6320-05b9-4613-a833-f14faa281efc",
    prod_nm: "Ghế Sofa Góc chất liệu da bò Ý nhập khẩu Divano L-689 màu Camel",
    suppl_no: "ee2e4677-3731-45eb-a23d-8a7197b1d1b7",
    thumb_img: null,
    prod_img: "0a216109-0309-4381-a022-dc6750049744",
    prod_img_files: ["0a216109-0309-4381-a022-dc6750049744"],
    prod_3d_model: null,
    prod_3d_url: null,
    prod_desc:
      "Ghế Sofa Góc chất liệu da bò Ý nhập khẩu Divano L-689 màu Camel sử dụng chất liệu da bò thương hiệu GM đến từ Italia với chất lượng & độ bền cực cao. Bền Đẹp mãi theo thời gian. Da bò thật cực dày có tính năng ít thấm nước nên có chất lượng và khả năng chống bụi cao.",
    prod_type: "furniture",
    brand_nm: "DIVANO",
    model_num: "L-689",
    prod_unit_price: 55000000,
    prod_size: " 2950(W) x 870(L) x 430(H)",
    prod_height: "2950",
    prod_length: "870",
    prod_depth_height: "430",
    prod_weight: null,
    prod_type_lv1: {
      prod_type_no: 1,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Furniture",
      prod_type_lvl: 1,
      parnt_type_no: null,
    },
    prod_type_lv2: {
      prod_type_no: 6,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Sofa",
      prod_type_lvl: 2,
      parnt_type_no: 1,
    },
    made_by: null,
    made_in: null,
    cat_by_mat: ["wood", "feather"],
    cat_by_room: ["living room"],
    cat_by_color: ["camel", "wood/brown"],
    cat_by_style: ["retro"],
    cat_by_type: ["Sofa"],
    tag: ["Sofa", "Brown"],
    prod_info_url: null,
  },
  {
    date_created: "2023-04-02T14:12:09.380Z",
    date_updated: null,
    prod_no: 6,
    sort: null,
    status: "published",
    user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
    user_updated: null,
    prod_nm: "Kệ Tivi Mặt Đá chân inox TV1724 (mặt đá tự nhiên màu đen)",
    suppl_no: "ee2e4677-3731-45eb-a23d-8a7197b1d1b7",
    thumb_img: null,
    prod_img: "1f43eea4-557a-4863-887c-56aeadb59bab",
    prod_img_files: ["1f43eea4-557a-4863-887c-56aeadb59bab"],
    prod_3d_model: null,
    prod_3d_url: null,
    prod_desc:
      "Kệ Tivi Mặt Đá BizNoiThat chân inox TV 1724 (mặt đá trắng) được thiết kế hình chữ nhật đầy phong cách mang tính thẩm mỹ cao, màu sắc trang nhã mang đặc trưng của gỗ óc chó, \n\nKích Thước: Dài 2000 x Sâu 450 x  Cao400Hmm\nVật liệu: Mặt bàn là đá tự nhiên Marble kết hợp với khung chân inox 304 cao cấp\nXuất Xứ: Nhập Khẩu HongKong\nMàu Sắc: Mặt đá màu trắngma hoặc màu đen  kết hợp chân inox màu trắng",
    prod_type: "furniture",
    brand_nm: "BIZNOITHAT",
    model_num: "TV1724",
    prod_unit_price: 16000000,
    prod_size: "2000 x 450 x 400 (H)",
    prod_height: "2000",
    prod_length: "450",
    prod_depth_height: "400",
    prod_weight: null,
    prod_type_lv1: {
      prod_type_no: 1,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Furniture",
      prod_type_lvl: 1,
      parnt_type_no: null,
    },
    prod_type_lv2: {
      prod_type_no: 3,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Bed",
      prod_type_lvl: 2,
      parnt_type_no: 1,
    },
    made_by: null,
    made_in: "HongKong",
    cat_by_mat: ["wood", "steel", "stone"],
    cat_by_room: ["living room"],
    cat_by_color: ["black&white/walnut"],
    cat_by_style: ["modern/contemporary"],
    cat_by_type: ["Bed"],
    tag: ["Bed", "Silver", "Brown"],
    prod_info_url:
      "https://biznoithat.com/ke-tivi-mat-da-chan-inox-tv1724-mat-da-tu-nhien-mau-den",
  },
  {
    date_created: "2023-04-02T14:18:03.909Z",
    date_updated: null,
    prod_no: 7,
    sort: null,
    status: "published",
    user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
    user_updated: null,
    prod_nm: "Bàn phụ tròn gấp được từ kim loại Biz-8052R",
    suppl_no: "ee2e4677-3731-45eb-a23d-8a7197b1d1b7",
    thumb_img: null,
    prod_img: "d518efed-e5c3-4f74-9914-b1a1e4571997",
    prod_img_files: ["d518efed-e5c3-4f74-9914-b1a1e4571997"],
    prod_3d_model: null,
    prod_3d_url: null,
    prod_desc:
      "Bàn phụ tròn gấp được từ kim loại Biz-8052R được thiết kế hình chữ nhật đầy phong cách mang tính thẩm mỹ cao, màu sắc trang nhã, kiểu dáng đơn giản hiện đại, phong cách Bắc Âu. Đây sẽ là điểm nhấn nổi bật cho phòng khách nhà bạn.\n\nVật Liệu:\n- Mặt \u001dbàn được làm bằng kính cường lực siêu bền\n- Màu Sắc: Mặt kính màu đen, khung kim loại mạ crom màu Gold\n- Kích thước: Bàn chữ nhật: Dài 1300, Rộng 700, Cao 430Hmm - Bàn tròn: Đường Kính D900, Cao 430Hmm\n- Bảo hành: 12 Tháng\n- Hàng có sẵn & giao ngay trong ngày.",
    prod_type: "furniture",
    brand_nm: "BIZNOITHAT",
    model_num: "BIZ-8052R",
    prod_unit_price: 12000000,
    prod_size: "Length 1300, Width 700, Height 430H (mm)",
    prod_height: "1300",
    prod_length: "700",
    prod_depth_height: "430",
    prod_weight: null,
    prod_type_lv1: {
      prod_type_no: 1,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Furniture",
      prod_type_lvl: 1,
      parnt_type_no: null,
    },
    prod_type_lv2: {
      prod_type_no: 7,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Table",
      prod_type_lvl: 2,
      parnt_type_no: 1,
    },
    made_by: null,
    made_in: null,
    cat_by_mat: ["steel", "glass"],
    cat_by_room: ["living room"],
    cat_by_color: ["gold(metal)", "black(glass)"],
    cat_by_style: ["modern/contemporary"],
    cat_by_type: ["Table"],
    tag: ["Table", "Silver"],
    prod_info_url:
      "https://biznoithat.com/ban-tra-phong-khach-mat-kinh-cuong-luc-biz-8052r",
  },
  {
    date_created: "2023-04-02T14:31:29.205Z",
    date_updated: "2023-04-02T15:49:18.325Z",
    prod_no: 8,
    sort: null,
    status: "published",
    user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
    user_updated: "e28c6320-05b9-4613-a833-f14faa281efc",
    prod_nm: "Bàn ăn gỗ óc chó xuất Nhật Biznoithat Walnut-05",
    suppl_no: "ee2e4677-3731-45eb-a23d-8a7197b1d1b7",
    thumb_img: null,
    prod_img: "aeba13a6-1d3a-49cb-860b-5413281b2834",
    prod_img_files: ["aeba13a6-1d3a-49cb-860b-5413281b2834"],
    prod_3d_model: null,
    prod_3d_url: null,
    prod_desc:
      "Thiết kế đơn giản, tinh tế và sang trọng\nBàn ăn Gỗ óc chó xuất Nhật biznoithat Walnut-05 có kích thước 2000 x 950 x 740 mm. Sản phẩm thiết kế đơn giản, màu sắc trang nhã, kiểu dáng tinh tế tạo nên cảm giác thoải mái và dễ chịu cho các thành viên trong gia đình, mỗi khi sử dụng. \n\nKích thước: 2000 x 950 x 740Hmm\nVật Liệu: Gỗ Óc Chó Bắc Mỹ\nMàu Sắc: Walnut tự nhiên\nXuất Xứ: SX tại Việt Nam, xuất khẩu đi Mỹ\nBảo hành: 12 tháng",
    prod_type: "furniture",
    brand_nm: "BIZ NỘI THẤT",
    model_num: "BÀN ĂN ÓC CHÓ WALNUT-05",
    prod_unit_price: 14500000,
    prod_size: " 2000 x 950 x 740H (mm)",
    prod_height: "2000",
    prod_length: "950",
    prod_depth_height: "740",
    prod_weight: null,
    prod_type_lv1: {
      prod_type_no: 1,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Furniture",
      prod_type_lvl: 1,
      parnt_type_no: null,
    },
    prod_type_lv2: {
      prod_type_no: 7,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Table",
      prod_type_lvl: 2,
      parnt_type_no: 1,
    },
    made_by: null,
    made_in: "Vietnam",
    cat_by_mat: ["walnut"],
    cat_by_room: ["dining room"],
    cat_by_color: ["walnut"],
    cat_by_style: ["modern/contemporary"],
    cat_by_type: ["Table"],
    tag: ["Table", "Brown"],
    prod_info_url:
      "https://biznoithat.com/bo-ban-an-6-ghe-go-oc-cho-xuat-nhat-biznoithat-ba-oc04",
  },
  {
    date_created: "2023-04-02T14:40:09.419Z",
    date_updated: null,
    prod_no: 9,
    sort: null,
    status: "published",
    user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
    user_updated: null,
    prod_nm: "Walnut Decorative Shelves K-1214W",
    suppl_no: "ee2e4677-3731-45eb-a23d-8a7197b1d1b7",
    thumb_img: null,
    prod_img: "7c14a597-7b99-4816-ae24-2cc5b0dd9483",
    prod_img_files: ["7c14a597-7b99-4816-ae24-2cc5b0dd9483"],
    prod_3d_model: null,
    prod_3d_url: null,
    prod_desc:
      "Vật liệu: Gỗ óc chó tự nhiên nhập khẩu Bắc Mỹ\nMàu sắc: Walnut\nXuất xứ: Việt Nam xuất khẩu Nhật Bản\nBảo hành: 12 tháng\n\nKích thước:\n- Kệ Thấp: Rộng 1200 x Sâu 370 Cao 1080Hmm\n- Kệ Cao: Rộng 1200 x Sâu 370 Cao 1430Hmm",
    prod_type: "furniture",
    brand_nm: "BIZ NỘI THẤT",
    model_num: "K-1214W",
    prod_unit_price: 12500000,
    prod_size: "300 W x 600 D 300 H (mm)",
    prod_height: "300",
    prod_length: "600",
    prod_depth_height: "300",
    prod_weight: null,
    prod_type_lv1: {
      prod_type_no: 1,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Furniture",
      prod_type_lvl: 1,
      parnt_type_no: null,
    },
    prod_type_lv2: {
      prod_type_no: 8,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Bookshelf",
      prod_type_lvl: 2,
      parnt_type_no: 1,
    },
    made_by: null,
    made_in: "Vietnam",
    cat_by_mat: ["wood", "walnut"],
    cat_by_room: ["living room"],
    cat_by_color: ["walnut"],
    cat_by_style: ["modern/contemporary"],
    cat_by_type: ["Bookshelf"],
    tag: ["Bookshelf", "Brown"],
    prod_info_url: "https://biznoithat.com/ke-trang-tri-oc-cho-k-1210",
  },
  {
    date_created: "2023-04-02T14:46:42.902Z",
    date_updated: null,
    prod_no: 10,
    sort: null,
    status: "published",
    user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
    user_updated: null,
    prod_nm: "Tủ Rượu Gỗ Óc Chó TR-OC01",
    suppl_no: "ee2e4677-3731-45eb-a23d-8a7197b1d1b7",
    thumb_img: null,
    prod_img: "0b2bec5a-5706-42b2-bbb7-3506f6d4aea0",
    prod_img_files: ["0b2bec5a-5706-42b2-bbb7-3506f6d4aea0"],
    prod_3d_model: null,
    prod_3d_url: null,
    prod_desc:
      "Thiết kế đơn giản, tinh tế và sang trọng\nTủ rượu gỗ óc chó TR-01 được thiết kế đầy phong cách mang tính thẩm mỹ cao, màu sắc trang nhã mang đặc trưng của gỗ óc chó, kiểu dáng tinh tế tạo nên cảm giác thoải mái và dễ chịu cho các thành viên trong gia đình, mỗi khi sử dụng.\nTủ có kiểu dáng cao với kích thước 2100x800mm nên không chiếm nhiều diện tích xếp đặt. Màu nâu walnut thanh lịch, cùng thiết kế đơn giản, phù hợp với mọi không gian nội thất như: phòng khách, phòng ngủ, văn phòng làm việc, quán cà phê...\n\nVật liệu: Gỗ óc chó tự nhiên nhập khẩu Bắc Mỹ\nMàu sắc: Walnut\nXuất xứ: Việt Nam xuất khẩu Nhật Bản\nKích Thước: Cao 2100 x Rộng 800\nBảo hành: 12 tháng\nĐặc Điểm: Có hệ thống đèn chiếu sáng",
    prod_type: "furniture",
    brand_nm: "BIZNOITHAT",
    model_num: "TR-OC01",
    prod_unit_price: 15500000,
    prod_size: " 2100 H x 800W (mm)",
    prod_height: "2100",
    prod_length: "800",
    prod_depth_height: "",
    prod_weight: null,
    prod_type_lv1: {
      prod_type_no: 1,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Furniture",
      prod_type_lvl: 1,
      parnt_type_no: null,
    },
    prod_type_lv2: {
      prod_type_no: 9,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Cupboard",
      prod_type_lvl: 2,
      parnt_type_no: 1,
    },
    made_by: null,
    made_in: null,
    cat_by_mat: ["wood", "glass"],
    cat_by_room: ["living room", "dining room"],
    cat_by_color: ["wood/brown"],
    cat_by_style: ["retro"],
    cat_by_type: ["Cupboard"],
    tag: ["Cupboard", "Brown"],
    prod_info_url: "https://biznoithat.com/tu-ruou-go-oc-cho-tr-oc01",
  },
  {
    date_created: "2023-04-02T15:06:29.774Z",
    date_updated: "2023-04-02T15:53:47.426Z",
    prod_no: 11,
    sort: null,
    status: "published",
    prod_nm: "Smart Tivi QLED Samsung 4K 55 inch QA55Q80R",
    prod_3d_url: null,
    prod_desc:
      "Kiểu dáng hiện đại, sang trọng\nSmart Tivi QLED Samsung 4K 55 inch QA55Q80R với thiết kế màn hình tràn viền siêu mỏng 0.5 cm (thậm chí còn mỏng hơn iPhone 7 Plus) cùng giải pháp dây cáp ẩn đột phá One Invisible Connection không chỉ tôn lên nét thẩm mỹ cho tivi mà còn biến tivi  trở thành điểm nhấn duy nhất và sang trọng trong không gian của ngôi nhà bạn.\n\nVới kích thước màn hình lớn, tivi Samsung 55 inch sẽ phù hợp cho không gian rộng như phòng khách, phòng hội nghị, phòng họp,...",
    prod_type: "appliance",
    brand_nm: "Samsung",
    model_num: "QA55Q80R",
    prod_unit_price: 12950000,
    prod_size: "1230.6 x 792.1 x 237.5 mm (WxHxD)",
    prod_weight: "20.5 kg",
    made_by: "Samsung",
    made_in: "Vietnam",
    cat_by_mat: null,
    cat_by_room: ["living room"],
    cat_by_color: ["Carbon Silver"],
    cat_by_style: ["minimal/simple"],
    cat_by_type: ["TV"],
    tag: ["TV", "QLED", "Carbon Silver"],
    prod_info_url:
      "https://www.lazada.vn/products/smart-tivi-qled-samsung-4k-55-inch-qa55q80r-i2232798756-s10723023770.html",
    cat_by_type: null,
    prod_height: null,
    prod_length: null,
    prod_depth_height: null,
    suppl_no: "b13cc5fe-30dd-48a9-8c1d-58779d198012",
    thumb_img: null,
    prod_img: "aab31268-015f-4868-9798-704e096eb71d",
    prod_3d_model: null,
    prod_type_lv1: {
      prod_type_no: 50,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Appliances",
      prod_type_lvl: 1,
      parnt_type_no: null,
    },
    prod_type_lv2: {
      prod_type_no: 51,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "TV",
      prod_type_lvl: 2,
      parnt_type_no: 50,
    },
    prod_img_files: ["aab31268-015f-4868-9798-704e096eb71d"],
  },
  {
    date_created: "2023-04-02T15:19:47.550Z",
    date_updated: null,
    prod_no: 12,
    sort: null,
    status: "published",
    user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
    user_updated: null,
    prod_nm: "TỦ LẠNH LG INVERTER 649 LÍT GR-B257JDS - MODEL 2022",
    suppl_no: "b13cc5fe-30dd-48a9-8c1d-58779d198012",
    thumb_img: null,
    prod_img: "55dc1bed-0c48-49b6-b98d-a3aa5d423742",
    prod_img_files: ["55dc1bed-0c48-49b6-b98d-a3aa5d423742"],
    prod_3d_model: null,
    prod_3d_url: null,
    prod_desc:
      "Tủ lạnh LG Inverter 649 Lít GR-B257JDS là mẫu tủ lạnh Side by side được trang bị động cơ Smart Inverter giúp tiết kiệm điện năng. Tích hợp khả năng làm lạnh đa chiều giúp hơi lạnh lan toả đều đến các ngăn.\n\nTổng quan thiết kế\n\n- Đây là mẫu tủ lạnh Side by side, chất liệu cửa tủ được làm từ thép không gỉ với màu bạc sang trọng, tránh được tình trạng bị bám vân tay.\n- Tay mở cửa được thiết kế âm tủ giúp lược bỏ các chi tiết thừa, đem đến sự tối giản, hiện đại.\n- Mẫu tủ lạnh này thích hợp đặt trong phòng khách, nhà bếp.\n- Dung tích 649 lít phục vụ cho nhu cầu gia đình từ 5 thành viên trở lên.\n",
    prod_type: "appliance",
    brand_nm: "LG",
    model_num: "GR-B257JDS",
    prod_unit_price: 14989000,
    prod_size: "H 179 cm x W 91.3 cm x D 74.3 cm",
    prod_height: "179",
    prod_length: "91.3",
    prod_depth_height: "74.3",
    prod_weight: "103 kg",
    prod_type_lv1: {
      prod_type_no: 50,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Appliances",
      prod_type_lvl: 1,
      parnt_type_no: null,
    },
    prod_type_lv2: {
      prod_type_no: 52,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Refrigerator",
      prod_type_lvl: 2,
      parnt_type_no: 50,
    },
    made_by: "LG",
    made_in: "China",
    cat_by_mat: null,
    cat_by_room: ["kitchen"],
    cat_by_color: ["silver"],
    cat_by_style: ["modern/contemporary"],
    cat_by_type: ["Refrigerator"],
    tag: ["Refrigerator", "Silver"],
    prod_info_url:
      "https://www.lazada.vn/products/bao-hanh-chinh-hang-24-thang-tu-lanh-lg-inverter-649-lit-gr-b257jds-model-2022-i2222435321-s10639426097.html",
  },
  {
    date_created: "2023-04-02T15:28:37.402Z",
    date_updated: null,
    prod_no: 13,
    sort: null,
    status: "published",
    user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
    user_updated: null,
    prod_nm:
      "Lò vi sóng tráng men SAMSUNG BESPOKE Dòng nướng 23L MG23T5018CE/SV",
    suppl_no: "b13cc5fe-30dd-48a9-8c1d-58779d198012",
    thumb_img: null,
    prod_img: "a6dc1674-a33e-4adf-9c4d-c4827b7d053b",
    prod_img_files: ["a6dc1674-a33e-4adf-9c4d-c4827b7d053b"],
    prod_3d_model: null,
    prod_3d_url: null,
    prod_desc:
      "Đặc tính sản phẩm Lò vi sóng tráng men SAMSUNG BESPOKE Dòng nướng 23L MG23T5018CE/SV\nThương hiệuSamsungSKU1625800792_VNAMZ-7056305774Loại lò vi sóngĐể bànpower_consumption1000-2000Loại Bảo HànhBảo hành điện tửInverterKhông có inverteroven_size1 cu ft and belowXuất xứMalaysiaĐặc điểm lò vi sóngĐiều khiển cảm ứngThời gian bảo hành2 NămCông Suất Tiêu Thụ1250",
    prod_type: "appliance",
    brand_nm: "Samsung",
    model_num: "MG23T5018CE/SV",
    prod_unit_price: 4390000,
    prod_size: "489 W x 381D 275 H mm",
    prod_height: "489",
    prod_length: "381",
    prod_depth_height: "275",
    prod_weight: "12.7 kg",
    prod_type_lv1: {
      prod_type_no: 50,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Appliances",
      prod_type_lvl: 1,
      parnt_type_no: null,
    },
    prod_type_lv2: {
      prod_type_no: 53,
      status: "published",
      sort: null,
      user_created: "e28c6320-05b9-4613-a833-f14faa281efc",
      date_created: "2023-04-11T04:27:21.562Z",
      user_updated: null,
      date_updated: null,
      prod_type_nm: "Oven",
      prod_type_lvl: 2,
      parnt_type_no: 50,
    },
    made_by: "Samsung",
    made_in: "Malaysia",
    cat_by_mat: ["steel"],
    cat_by_room: ["kitchen"],
    cat_by_color: ["white/cream"],
    cat_by_style: ["modern/contemporary"],
    cat_by_type: ["Oven"],
    tag: ["Oven", "Silver"],
    prod_info_url:
      "https://www.lazada.vn/products/lo-vi-song-trang-men-samsung-bespoke-dong-nuong-23l-mg23t5018cesv-i1625800792-s7056305774.html",
  },
];

export const getCategoryType = async (category) => {
  // TODO: 상품카테고리 api 억세스
  const cat = [];
  mockProduct.map((prod) => {
    prod.prod_type_lv2 &&
      !cat.find((c) => c.prod_type_no === prod.prod_type_lv2.prod_type_no) &&
      cat.push(prod.prod_type_lv2);
  });
  return Promise.resolve(cat);
};

export const getColorType = async (color) => {
  // TODO: 상품색상 api 억세스
  const col = [];
  mockProduct.map((prod) => {
    prod.cat_by_color.map((clr) => col.push(clr));
  });
  return Promise.resolve([...new Set(col)]);
};

export const getProduct = async (prod_no) => {
  // TODO: 상품 api 억세스
  let prod = mockProduct.find((prod) => {
    console.log(`getProduct prod_no : ${prod_no}`);
    return prod.prod_no == prod_no;
  });
  return Promise.resolve(prod);
};

export const getProductCount = async ({
  suppl_no,
  brand_nm,
  prod_type,
  color,
  keyword,
}) => {
  // TODO: 상품 갯수 api 억세스
  let cnt = mockProduct.filter(() => {
    return getProductList({ suppl_no, brand_nm, prod_type, color, keyword })
      .length;
  }).length;
  return Promise.resolve(cnt);
};

export const getProductList = async ({
  suppl_no,
  brand_nm,
  cat_by_type,
  color,
  keyword,
}) => {
  // TODO: 상품 목록 api 억세스
  // TODO: 제품 유형 세부 추가
  console.log(`getProductList keyword : ${keyword}`);
  console.log(`getProductList suppl_no : ${suppl_no}`);
  console.log(`getProductList color : ${color}`);
  console.log(`getProductList brand_nm : ${brand_nm}`);
  console.log(`getProductList cat_by_type : ${cat_by_type}`);
  let prodList = mockProduct.filter((item) => {
    return (
      suppl_no &&
      item.suppl_no === suppl_no &&
      // (!cat_by_type ||
      //   (cat_by_type && item.cat_by_type.find((cat) => cat === cat_by_type))) &&
      (!cat_by_type ||
        (cat_by_type &&
          item.prod_type_lv2?.prod_type_no.toString() === cat_by_type)) &&
      (!color || (color && item.cat_by_color.find((clr) => clr === color))) &&
      (!keyword ||
        (keyword && item.prod_nm.toLowerCase().includes(keyword.toLowerCase())))
    );
  });

  return Promise.resolve(prodList);
};
