import { createSlice } from "@reduxjs/toolkit";

const initialState =
  JSON.parse(localStorage.getItem("demoOrderItemList")) ?? [];

export const demoOrderItemListSlice = createSlice({
  name: "demoOrderItemList",
  initialState: initialState,
  reducers: {
    setOrderItemList: (state, action) => {
      state = action.payload;
      localStorage.setItem("demoOrderItemList", JSON.stringify(state));
    },
    updateOrderItemDeliveryStatus: (state, action) => {
      /*
        payload = {
          suppl_no: suppl_no,
          deliv_status : deliv_status,
        }
      */
      console.log(
        "demoOrderItemList:updateOrderItemDeliveryStatus, action.payload=, state.deliv_status",
        action.payload,
        state.deliv_status
      );
      const index = state.findIndex(
        (orderItem) => orderItem.suppl_no.org_no === action.payload.suppl_no
      );
      console.log(
        "demoOrderItemList:updateOrderItemDeliveryStatus, indexFound=",
        index
      );
      if (index > -1) {
        state[index].deliv_status = action.payload.deliv_status;

        console.log(
          "demoOrderItemList:updateOrderItemDeliveryStatus, state=",
          state
        );
        //state = [...state];
        localStorage.setItem("demoOrderItemList", JSON.stringify(state));
      }
    },
  },
});

export const { setOrderItemList, updateOrderItemDeliveryStatus } =
  demoOrderItemListSlice.actions;
export const selectDemoOrderItemList = (state) => state.demoOrderItemList;

export default demoOrderItemListSlice.reducer;
