import { createSlice } from "@reduxjs/toolkit";
import { mockLastAccessDate } from "../../api/supplier";

const initialState = {
  latestAccessDate: mockLastAccessDate,
};

export const accessInfoSlice = createSlice({
  name: "accessInfo",
  initialState: initialState,
  reducers: {
    setLatestAccessDate: (state, action) => {
      state.latestAccessDate = action.payload;
    },
  },
});

export const { setLatestAccessDate } = accessInfoSlice.actions;
export const selectLatestAccessDate = (state) =>
  state.accessInfo.latestAccessDate;

export default accessInfoSlice.reducer;
