import React from "react";
import "./LoadingSpinner.css";

function LoadingSpinner() {
  return (
    <div className="loading-wrap">
      <div className="center">
        <div className="spinner">
          <svg>
            <circle className="active" cx={50} cy={50} r={28} />
          </svg>
        </div>
        <div className="texter">Loading</div>
      </div>
    </div>
  );
}

export default LoadingSpinner;
