import { configureStore } from "@reduxjs/toolkit";
import accessInfoReducer from "./slices/accessInfoSlice";
import userInfoReducer from "./slices/userInfoSlice";
import demoInfoReducer from "./slices/demoInfoSlice";
import demoContractListReducer from "./slices/demoContractListSlice";
import demoOrderItemListSlice from "./slices/demoOrderItemListSlice";

export const store = configureStore({
  reducer: {
    demoInfo: demoInfoReducer,
    demoOrderItemList: demoOrderItemListSlice,
    demoContractList: demoContractListReducer,
    accessInfo: accessInfoReducer,
    userInfo: userInfoReducer,
  },
});
