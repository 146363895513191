import { createSlice } from "@reduxjs/toolkit";

const initialState = JSON.parse(localStorage.getItem("demoContractList")) ?? [];

export const demoContractListSlice = createSlice({
  name: "demoContractList",
  initialState: initialState,
  reducers: {
    addContract: (state, action) => {
      console.log(
        "demoContractList:addContract, action.payload=",
        action.payload
      );
      state.push(action.payload);
      localStorage.setItem("demoContractList", JSON.stringify(state));
      console.log("demoContractList:addContract, contractList=", state);
    },
    updateContract: (state, action) => {
      console.log(
        "demoContractList:updateContract, action.payload=",
        action.payload
      );
      let index = state.findIndex(
        (contract) => contract.contr_no === action.payload.contr_no
      );
      if (index > -1) {
        state[index] = {
          ...state[index],
          order_status: action.payload.order_status,
          dpst_due_date: action.payload.dpst_due_date,
        };
        console.log(
          "demoContractList:updateContract, index, state[index]=",
          index,
          state[index]
        );
      }
      state = [...state];
      localStorage.setItem("demoContractList", JSON.stringify(state));
    },
  },
});

export const { addContract, updateContract } = demoContractListSlice.actions;
export const selectDemoContractList = (state) => state.demoContractList;
export const selectDemoContractDetail = (state) =>
  state.demoContractList[0] || null;

export default demoContractListSlice.reducer;
