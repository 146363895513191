import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  existPlan: false,
  agentNbrOkay: false,
};

export const demoInfoSlice = createSlice({
  name: "demoInfo",
  initialState: initialState,
  reducers: {
    setExistPlan: (state, action) => {
      state.existPlan = action.payload;
    },
    setAgentNbrOkay: (state, action) => {
      state.agentNbrOkay = action.payload;
    },
  },
});

export const { setExistPlan, setAgentNbrOkay } = demoInfoSlice.actions;
export const selectExistPlan = (state) => state.demoInfo.existPlan;
export const selectAgentNbrOkay = (state) => state.demoInfo.agentNbrOkay;

export default demoInfoSlice.reducer;
